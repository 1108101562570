
import { defineComponent, reactive, computed, onMounted, toRefs, ref } from 'vue'
import { useStore } from 'vuex'

const api = process.env.VUE_APP_BASEURL
import axios from '../axios'
import { User } from '@/classes/user'

export default defineComponent({
  setup () {
    const store = useStore()
    const isLoading = ref<boolean>(false)
    const auth_user = computed(() => store.state.AuthUser.user)

    const formProfile = reactive({
      user_name: '',
      email: '',
      salutation: '',
      first_name: '',
      last_name: '',
      middle_name: '',
    })

    const formPassword = reactive({
      password: '',
      confirm_password: '',
    })

    const getUser = async () => {
      const url = 'auth/user'
      const {
        user_name,
        email,
        first_name,
        middle_name,
        last_name,
        salutation,
      } = toRefs(formProfile)
      const response = await axios.get(url)
      user_name.value = response.data.data.user_name
      email.value = response.data.data.email
      first_name.value = response.data.data.first_name
      last_name.value = response.data.data.last_name
      middle_name.value = response.data.data.middle_name
      salutation.value = response.data.data.salutation
      const u: User = response.data.data
      await store.dispatch('AuthUser/setUser', new User(
          u.id,
          u.email,
          u.last_name,
          u.first_name,
          u.role,
          u.sidebar
      ))
    }

    const updatePassword = () => {
      isLoading.value = true
      store.dispatch('User/updatePassword', formPassword).then(() => {
        isLoading.value = false
      }).catch(() => {
        isLoading.value = false
      })
    }

    const updateProfile = () => {
      store.dispatch('User/updateProfile', formProfile)
    }

    onMounted(getUser)

    return {
      formProfile,
      formPassword,
      auth_user,
      updatePassword,
      updateProfile,
      isLoading
    }
  },
})

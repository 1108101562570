import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "Account Profile" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            xs: { span: 24 },
            lg: { span: 11 }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form, {
                labelCol: { span: 10 },
                labelAlign: "left",
                layout: "vertical",
                onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.updateProfile(_ctx.formProfile)), ["prevent"])),
                id: "formProfile"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Username" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "text",
                        required: "",
                        value: _ctx.formProfile.user_name,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formProfile.user_name) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { label: "Email" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "text",
                        id: "email",
                        required: "",
                        value: _ctx.formProfile.email,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formProfile.email) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { label: "Salutation" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "text",
                        value: _ctx.formProfile.salutation,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formProfile.salutation) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { label: "First Name" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "text",
                        required: "",
                        value: _ctx.formProfile.first_name,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formProfile.first_name) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { label: "Middle Name" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "text",
                        value: _ctx.formProfile.middle_name,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formProfile.middle_name) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { label: "Last Name" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "text",
                        required: "",
                        value: _ctx.formProfile.last_name,
                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formProfile.last_name) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { "wrapper-col": { span: 4 } }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        key: "submit",
                        type: "primary",
                        htmlType: "submit",
                        loading: false
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Update Profile ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            xs: { span: 0 },
            lg: { span: 2 },
            style: {"text-align":"center"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_divider, {
                type: "vertical",
                style: {"height":"100%"}
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            xs: { span: 24 },
            lg: { span: 11 }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_spin, { spinning: _ctx.isLoading }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form, {
                    labelCol: { span: 10 },
                    labelAlign: "left",
                    layout: "vertical",
                    onSubmit: _withModifiers(_ctx.updatePassword, ["prevent"]),
                    id: "formPassword"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { label: "Password" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_password, {
                            "allow-clear": "",
                            placeholder: "",
                            value: _ctx.formPassword.password,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formPassword.password) = $event)),
                            required: ""
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, {
                        label: "Confirm Password",
                        style: {"overflow-wrap":"break-word","word-wrap":"break-word","hyphens":"auto"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_password, {
                            "allow-clear": "",
                            placeholder: "",
                            value: _ctx.formPassword.confirm_password,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formPassword.confirm_password) = $event)),
                            required: ""
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, { "wrapper-col": { span: 4 } }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_space, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                key: "submit",
                                type: "primary",
                                htmlType: "submit",
                                loading: false
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Update Password ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onSubmit"])
                ]),
                _: 1
              }, 8, ["spinning"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}